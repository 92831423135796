import axios from 'axios';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import Layout from '../components/Layout';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { colors } from '../utils/siteVars';

const StyledIndexPage = styled.div`
  display: flex;
  .login-left-section {
    width: 40vw;
    min-width: 500px;
    background: ${colors.white};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 0px 14px rgba(0, 0, 0, 0.1);

    form {
      margin-bottom: 10rem;
      h3 {
        margin-bottom: 3rem;
        margin-top: 1rem;
      }
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-grow: 1;
      input,
      button {
        max-width: calc(500px - 6rem);
        width: 100%;
      }
    }
    .login-left-section-bottom {
      background: ${colors.lightBlue};
      color: ${colors.white};
      text-align: center;
      padding: 1.5rem;
      a {
        text-decoration: underline;
      }
    }
  }
  img {
    width: 80px;
  }
  .login-right-section {
    background-size: cover;
    background-image: url('/images/water.jpg');
    width: 100%;
    opacity: 0.2;
  }
  @media (max-width: 1000px) {
    display: block;
    .login-left-section {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
      form {
        padding: 1rem;
      }
    }
    .login-right-section {
      display: none;
    }
  }
`;

const IndexPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const handleLogin = (e) => {
    console.log('API_URL', process.env);
    dispatch({ type: 'LOADING', value: true });
    e.preventDefault();
    const body = {
      user: {
        email: email,
        password: password,
      },
    };

    axios
      .post(process.env.GATSBY_BACKEND_API_URL + 'users/no-auth/login', body)
      .then((res) => {
        const { user, token } = res.data;
        dispatch({ type: 'TOKEN_VERIFIED', token: token, user: user });
        localStorage.setItem('token', token);
        setCookie('token', token, 24);
        navigate('/notifications');
      })
      .catch((err) => {
        dispatch({ type: 'ALERT', content: 'Felaktiga inloggningsuppgifter' });
        dispatch({ type: 'LOADING', value: false });
        //console.log(err.response)
      });
  };

  useEffect(() => {
    if (state.tokenVerified) {
      navigate('/notifications');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCookie = (name, value, days) => {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie =
      name +
      '=' +
      (value || '') +
      expires +
      '; path=/; samesite=none; secure; domain=.bucketmedia.se;';
  };

  return (
    <Layout clean>
      {state.tokenVerified ? null : (
        <StyledIndexPage data-test="login-page">
          <div className="login-left-section">
            <form onSubmit={handleLogin}>
              <img src="/images/logo.png" alt="logo" />
              <h1>Logga in</h1>
              <input
                data-test="login-email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Mailadress"
              />
              <input
                data-test="login-password"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Lösenord"
              />
              <Button type="submit" data-test="login-button">
                Logga in
              </Button>
            </form>
            <div className="login-left-section-bottom">
              <a href="https://h2oteknik.se">Gå till H2Oteknik.se</a>
            </div>
          </div>
          <div className="login-right-section"></div>
        </StyledIndexPage>
      )}
    </Layout>
  );
};

export default IndexPage;
